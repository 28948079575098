@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto+Flex:wght@300;400;500;700&display=swap');
@import '../node_modules/normalize.css/normalize.css';

body {
  margin: 0;
  font-family: 'Roboto Flex', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #01080e;
  color: #fff;
}

.app {
  font-family: 'Roboto Flex', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
