.clasification {
	color: #fff;
	background-color: #031627;
	border: 1px solid #052642;
	border-radius: 3px;
}
.clasification h3 {
	font-size: 24px;
	text-transform: uppercase;
	line-height: 1.4;
	font-weight: 500;
}

.clasification-table {
	font-size: 14px;
	line-height: 1.7;
	width: 100%;
	border-color: #052642;
	table-layout: fixed;
	border-collapse: collapse;

}

.table-container {
	max-height: 250px; /* Adjust the height as needed */
	overflow-y: scroll;
  }

  .clasification-table th, .clasification-table td {
	padding: 8px;
	border-top: 1px solid #052642;
	white-space: nowrap;
	overflow: hidden;
	/* text-overflow: ellipsis; */
  }


.clasification-table tr {
	border-top: 1px solid #052642;
}
