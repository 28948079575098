.management{
	color: #fff;
	background-color: #1a252f;
	border: 1px solid #253443;
	border-radius: 3px;
}
.management h3{
	font-size: 24px;
	text-transform: uppercase;
	line-height: 1.4;
	font-weight: 500;
}
.management-table{
	font-size: 14px;
	line-height: 1.7;
	width: 100%;
	border-color: gray;
}
.management-table tr{
	border-top: 1px solid #253443;
}
.edit-mode input,
.edit-mode select {
	height: 35px;
	color: #fff;
}
