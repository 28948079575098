.main-margin {
  margin: 50px 100px;
}

/* Check if the browser supports media queries */
@supports (display: flex) {
  @media (max-width: 768px) {
    .main-margin {
      margin: 50px 20px;
      /* Adjust margin for screens <= 768px */
    }
  }

  @media (max-width: 576px) {
    .main-margin {
      margin: 20px 20px;
      /* Adjust margin for screens <= 576px */
    }
  }
}

.border-rounded {
  border-radius: 3px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #305476;
}

/* NAVBAR */
.navbar {
  display: block;
  padding: 0;
}

.navbar-black {
  display: flex;
  justify-content: flex-end;
  background-color: #000;
  padding: 15px 0;
}

.navbar-grey {
  background-color: #212b34;
}

.navbar-grey.scrolling {
  background-color: #212b34ee;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transition: all 0.3s;
}

.navbar-margin {
  display: flex;
  margin: 0 100px;
}

/* Responsive */
@supports (display: flex) {
  @media (max-width: 768px) {
    .navbar-margin {
      margin: 0 20px;
    }
  }
}

/* Dropdown */
.dropdown {
  text-align: end;
}

/* Dropdown small screens */
.dropdown-button {
  cursor: pointer;
  background-color: #3f6e9a;
  border-color: #305476;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: color 0.5s ease;
}

.open .dropdown-button {
  color: #305476;
}

/* Dropdown sports */
.sports-toggle {
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.dropdown-sports {
  background-color: #212b34;
  position: absolute;
  z-index: 100;
  width: 150px;
}

.dropdown-sports.scrolling {
  position: fixed;
}

.dropdown-list,
.dropdown-sports {
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 0;
  overflow: hidden;
  border: transparent;
  transition: border-top 0.5s ease-out;
  transition: max-height 0.5s ease-in-out;
}

.open .dropdown-list,
.open .dropdown-sports {
  max-height: 300px;
  border-top: 2px solid #3f6e9a;
  transition: border-top 0.5s ease-in;
  transition: max-height 0.5s ease-in-out;
}

.dropdown-link {
  padding: 8px 0;
  border-bottom: 1px solid #141b20;
}

.dropdown-link:last-child {
  border-bottom: 0px;
}

.nav-link {
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
}

.nav-link:hover {
  color: #3f6e9a;
}

.user-registered {
  color: #052642;
}

.fa-solid.fa-user {
  letter-spacing: 2px;
  color: #3f6e9a;
}

.navbar-logo {
  height: 60px;
  display: inline-block;
  text-align: center;
}

/* NAVBAR Logo responsive */
@supports (display: flex) {
  @media (max-width: 650px) {
    .navbar-logo {
      height: 50px;
    }
  }

  @media (max-width: 582px) {
    .navbar-logo {
      height: 45px;
    }
  }

  @media (max-width: 390px) {
    .navbar-logo {
      height: 40px;
    }
  }
}

.company-name {
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #f26722;
  text-transform: uppercase;
}

/* company-name responsive */
@supports (display: flex) {
  @media (max-width: 650px) {
    .company-name {
      font-size: 14px;
    }
  }

  @media (max-width: 582px) {
    .company-name {
      font-size: 12px;
    }
  }

  @media (max-width: 390px) {
    .company-name {
      font-size: 12px;
    }
  }
}

.company-sub-name {
  font-size: 16px;
  color: #fff;
}

/* company-sub-name responsive */
@supports (display: flex) {
  @media (max-width: 650px) {
    .company-sub-name {
      font-size: 14px;
    }
  }

  @media (max-width: 582px) {
    .company-sub-name {
      font-size: 12px;
    }
  }

  @media (max-width: 390px) {
    .company-sub-name {
      font-size: 12px;
    }
  }
}

.company-description {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #c6c6c6;
}

/* company-description responsive */
@supports (display: flex) {
  @media (max-width: 650px) {
    .company-description {
      font-size: 14px;
    }
  }

  @media (max-width: 582px) {
    .company-description {
      font-size: 10px;
    }
  }
}

/* SLIDERS */
.slider1,
.slider2,
.slider3 {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  position: relative;
  height: calc(88vh - 12em);
}

@supports (display: flex) {
  @media (max-width: 992px) {

    .slider1,
    .slider2,
    .slider3 {
      height: calc(50vh - 5em);
    }
  }

  @media (max-width: 576px) {

    .slider1,
    .slider2,
    .slider3 {
      height: calc(40vh - 5em);
    }
  }

  @media (max-width: 390px) {

    .slider1,
    .slider2,
    .slider3 {
      height: 30vh;
    }
  }
}

.slider1 {
  background-image: url("https://images-atletico-sanabria.s3.amazonaws.com/sliders/slider1.webp");
}

.slider2 {
  background-image: url("https://images-atletico-sanabria.s3.amazonaws.com/sliders/slider2.webp");
}

.slider3 {
  background-image: url("https://images-atletico-sanabria.s3.amazonaws.com/sliders/slider3.webp");
}

.text-slider {
  z-index: 1;
}

.text-holder {
  padding: 20px;
}

/* text-holder responsive */
@supports (display: flex) {
  @media (max-width: 992px) {
    .text-holder {
      padding: 14px;
    }
  }

  @media (max-width: 768px) {
    .text-holder {
      padding: 8px;
    }
  }

  @media (max-width: 576px) {
    .text-holder {
      padding: 5px;
    }
  }

  @media (max-width: 390px) {
    .text-holder {
      padding: 3px;
    }
  }
}

/* Texts sliders */
.bg-white,
.bg-blue {
  color: #0d0d0d;
  padding: 10px 15px;
  font-weight: 500;
  display: inline;
}

.bg-white {
  background-color: #fff;
  font-size: 31px;
}

.bg-blue {
  background-color: #3f6e9a;
  font-size: 22px;
  color: #fff;
}

/* text-sliders responsive */
@supports (display: flex) {
  @media (max-width: 992px) {

    .bg-white,
    .bg-blue {
      padding: 8px 12px;
    }

    .bg-white {
      font-size: 24px;
    }

    .bg-blue {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {

    .bg-white,
    .bg-blue {
      padding: 5px 10px;
    }

    .bg-white {
      font-size: 16px;
    }

    .bg-blue {
      font-size: 14px;
    }
  }

  @media (max-width: 576px) {

    .bg-white,
    .bg-blue {
      padding: 4px 8px;
    }

    .bg-white {
      font-size: 14px;
    }

    .bg-blue {
      font-size: 12px;
    }
  }

  @media (max-width: 390px) {

    .bg-white,
    .bg-blue {
      padding: 4px;
    }

    .bg-white,
    .bg-blue {
      font-size: 12px;
    }
  }
}


.btn-grey {
  background-color: #171e36;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
}

.btn-grey:hover {
  background-color: #151f28;
}

/* FORMS */
input, select {
  background-color: #0d1520;
  border: 1px solid #19283d;
  color: #666666;
  outline: none;
  transition: all 0.2s linear 0s;
}

input:focus {
  box-shadow: 0px 0px 5px #ed563b;
}

.login input {
  height: 45px;
  width: 280px;
  margin-bottom: 20px;
}


.new-member-form {
  background-color: #031627;
  border: 1px solid #031627;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* Login View */
.login {
  color: #c6c6c6;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#news {
  position: relative;
  background-color: #031626dc;
  overflow: hidden;
}

.fixed-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://neospor.joomlatema.net/templates/neospor/images/topsl-bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  z-index: -1;
}

.log-out{
  cursor: pointer;
  text-align: end;

}
.log-out .fa-user{
  color: red;
}

/* CardComponent */

.card-details-margin {
  margin: 0 100px;
  padding: 50px 0;
}

/* Check if the browser supports media queries */
@supports (display: flex) {
  @media (max-width: 768px) {
    .main-margin {
      margin: 0 20px;
      /* Adjust margin for screens <= 768px */
    }
  }

  @media (max-width: 576px) {
    .main-margin {
      margin: 0 20px;
      padding: 20px 0;
      /* Adjust margin for screens <= 576px */
    }
  }
}

/* CARD DETAILS */
.next-match {
	color: #fff;
	background-color: #031627;
	border: 1px solid #052642;
	border-radius: 3px;
}

.header-img{
	background-image: url("https://images-atletico-sanabria.s3.amazonaws.com/stripes.png");
	background-repeat: no-repeat;
	background-size: cover;
	right: 0;
	top: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;

}
.next-match-header {
	display: flex;
	text-transform: uppercase;
	background: linear-gradient(45deg, #171e24 40%, #354553 70%, #3f6e9a 100%);
	position: relative;
	z-index: 1;
}

.next-match-header>*:first-child {
	align-self: stretch;
	z-index: 1;
}
@supports (display: flex) {
	@media (max-width: 576px) {
	  .next-match-header>*:first-child {
		display: grid;
	  }
	}
}
.next-match-date {
	line-height: 1.1;
	display: flex;
	text-align: start;
	align-items: center;
	padding: 4px 0px 4px 20px;
}

.next-match-date h4 {
	font-size: 36px;
	padding: 0px 10px 0px 0px;
	margin: 0;
}
@supports (display: flex) {
	@media (max-width: 576px) {
	  .next-match-date h4 {
		font-size: 24px;
	  }
	}
}
.next-match-date span {
	font-weight: bold;
	text-transform: uppercase;
	font-size: 15px;
	line-height: 1.1;
}
@supports (display: flex) {
	@media (max-width: 576px) {
	  .next-match-date span {
		font-size: 12px;
	  }
	}
}
.next-match-middle {
	color: #fdc52c;
	font-size: 18px;
	font-style: italic;
	line-height: 1.1;
	font-weight: 700;
	text-align: center;
	padding: 6px 20px 4px 0px;
	z-index: 1;
}
@supports (display: flex) {
	@media (max-width: 576px) {
	  .next-match-middle {
		font-size: 14px;
	  }
	}
}
.publicity {
	display: block;
	letter-spacing: 0.5px;
	font-size: 11px;
	color: rgba(255, 255, 255, 0.6);
	font-style: normal;
}
@supports (display: flex) {
	@media (max-width: 576px) {
	  .publicity {
		font-size: 8px;
	  }
	}
}
.publicity h6 {
	color: #fff;
	font-size: 12px;
	display: inline-block;
	margin: 0;
}
@supports (display: flex) {
	@media (max-width: 576px) {
	  .publicity h6 {
		font-size: 9px;
	  }
	}
}

.next-match-body {
	display: flex;
	align-items: center;
}

.home-team,
.sport-description-wrapper {
	text-align: center;
	flex: 1;
}
.sport-description-wrapper span{
  font-size: 18px;
}
.home-team-name {
	font-size: 20px;
	margin-top: 0px;
}
@supports (display: flex) {
	@media (max-width: 576px) {
	  .home-team-name {
		font-size: 16px;
	  }
	}
}
.match-info {
	line-height: 1;
	padding: 30px 0px;
	text-align: center;
}

.clock {
	font-size: 44px;
	letter-spacing: 2px;
	font-family: 'Anton', sans-serif;
	background-color: #052642;
	background-image: linear-gradient(90deg, #3f6e9a 40%, #052642);
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.vs-sign {
	font-size: 24px;
	display: block;
	margin-top: 15px;
	font-family: 'Anton', sans-serif;
	background-color: #030507;
	background-image: linear-gradient(90deg, #3f6e9a 40%, #052642);
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.time-venue {
	font-size: 16px;
	margin-top: 20px;
	color: #ececec;
	line-height: 20px;
}

.time {
	display: block;
	margin-top: 25px;
}

.next-match-footer {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

.btn-blue{
	background-color: #3f6e9a;
	border-radius: 3px;
	transition: all 0.2s linear 0s;
	padding-top: 3px;
	padding-bottom: 3px;
	padding: 10px 15px;
	border: none;
	color: #ffffff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}

.btn-blue:hover {
	background-color: #c9171f;
	border-color: 1px solid #c9171f;
}
