.info-card{
	display: flex;
	background-color: #031627;
	color: #fff;
}
@supports (display: flex) {
	@media (max-width: 992px) {
	  .info-card {
		display: block;
	  }
	}
}
.info-card h2{
	font-size: 16px;
	font-weight: bold;
}
.btn-blue{
	background-color: #3f6e9a;
	border-radius: 3px;
	transition: all 0.2s linear 0s;
	padding-top: 3px;
	padding-bottom: 3px;
	padding: 6px 12px;
	border: none;
	color: #ffffff;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
}

.btn-blue:hover {
	background-color: #052642;
	border-color: 1px solid #052642;
}


